import React, { useEffect, useState } from "react";
import Layout from "@components/layouts/Layout";
import axios from "axios";
import Seo from "@components/common/Seo";
import { toast } from "react-toastify";
import SmallBanner from "@components/common/SmallBanner";
import dayjs from "dayjs";
import RenderPosts from "./components/renderPosts";
import RenderLoading from "./components/loading";
// import Pagination from "./components/pagination";
// import { v4 as uuid } from "uuid";

// Importamos el idioma español para dayjs y lo activamos
require("dayjs/locale/es");
dayjs.locale("es");

// Activamos plugin de dayjs para mostrar la fecha en formato relativo
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Component = ({ page }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            const toastId = toast.loading("Cargando datos...", {
                position: "bottom-center",
            });

            const rawData = await getPosts({ page });
            setData(rawData);

            setLoading(false);
            toast.dismiss(toastId);
        }

        fetchData();
    }, [page]);

    return (
        <>
            <RenderSeo />
            <Layout>
                <SmallBanner
                    title="iOpos Insight"
                    subtitle="Noticias, consejos y recursos imprescindibles para tener éxito en las oposiciones"
                    filename={"bgs/bgBlog"}
                />
                {loading && <RenderLoading />}
                {data && (
                    <div className="container py-20 mx-auto">
                        <RenderPosts
                            loading={loading}
                            data={data}
                            paginationBase={`/blog`}
                            firstExpanded={true}
                        />
                    </div>
                )}
            </Layout>
        </>
    );
};

const RenderSeo = () => {
    return (
        <Seo
            title={"iOpos Insight"}
            description={
                "Noticias, consejos y recursos imprescindibles para tener éxito en las oposiciones"
            }
        />
    );
};

const getPosts = async ({ page }) => {
    try {
        const url = `/.netlify/functions/posts`;
        const params = {
            page: page || 1,
        };

        const options = {
            headers: {
                "Content-Type": "application/json",
                "Strapi-Response-Format": "v4",
            },
        };

        const { data } = await axios.post(url, params, options);

        return data?.posts;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default Component;
